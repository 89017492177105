<script>
import { ref, computed, onMounted } from 'vue'
import md5 from 'md5'
import CertificationBadge from './CertificationBadge.vue'
import SocialLinks from './SocialLinks.vue'

export default {
  name: 'ProfileCard',
  components: {
    CertificationBadge,
    SocialLinks
  },
  setup() {
    const name = ref('Rodman Stuhlmuller')
    const description = ref('Tech enthusiast and problem solver based in Seattle. Passionate about crafting efficient systems and exploring the great outdoors.')
    const email = ref('rodman@stuhlmuller.net')
    const imageLoaded = ref(false)
    const imageError = ref(false)

    const gravatarUrl = computed(() => {
      const hash = md5(email.value.trim().toLowerCase())
      return `https://www.gravatar.com/avatar/${hash}?s=200`
    })

    const preloadGravatar = () => {
      const img = new Image()
      img.src = gravatarUrl.value

      return new Promise((resolve, reject) => {
        img.onload = () => {
          imageLoaded.value = true
          resolve()
        }
        img.onerror = () => {
          imageError.value = true
          reject(new Error('Failed to load Gravatar image'))
        }
      })
    }

    onMounted(async () => {
      try {
        await preloadGravatar()
      } catch (error) {
        console.error('Error preloading Gravatar:', error)
      }
    })

    return {
      name,
      description,
      gravatarUrl,
      imageLoaded,
      imageError
    }
  }
}
</script>

<template>
  <div class="card">
    <CertificationBadge />
    <div class="profile-pic-container">
      <div class="profile-pic-border">
        <div class="profile-pic-background">
          <div v-if="!imageLoaded && !imageError" class="loading-placeholder">
            <div class="loading-spinner"></div>
          </div>
          <img
            v-show="imageLoaded"
            :src="gravatarUrl"
            alt="Profile Picture"
            class="profile-pic"
          >
          <div v-if="imageError" class="error-placeholder">
            <span>!</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <h1>{{ name }}</h1>
      <p>{{ description }}</p>
      <SocialLinks />
    </div>
  </div>
</template>

<style scoped>
.card {
  position: relative;
  background-color: var(--card-bg);
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  padding: 2rem;
  text-align: center;
  max-width: 400px;
  width: 100%;
  transition: box-shadow 0.3s ease;
  box-sizing: border-box;
}

.profile-pic-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.profile-pic-border {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  background: conic-gradient(from 0deg,
      #ff6b6b,
      #feca57,
      #48dbfb,
      #ff9ff3,
      #ff6b6b);
}

.profile-pic-background {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: var(--card-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profile-pic {
  width: 195px;
  height: 195px;
  border-radius: 50%;
  object-fit: cover;
}

.loading-placeholder,
.error-placeholder {
  width: 195px;
  height: 195px;
  border-radius: 50%;
  background-color: var(--card-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--text-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-placeholder {
  background-color: #ffebee;
}

.error-placeholder span {
  font-size: 48px;
  color: #f44336;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: var(--text-color);
  margin-bottom: 1rem;
}

p {
  color: var(--text-color);
  line-height: 1.6;
  text-align: center;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .card {
    padding: 1.5rem;
  }

  .profile-pic-border {
    width: 180px;
    height: 180px;
  }

  .profile-pic-background {
    width: 170px;
    height: 170px;
  }

  .profile-pic,
  .loading-placeholder,
  .error-placeholder {
    width: 165px;
    height: 165px;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }
}
</style>